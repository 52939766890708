"use strict";

var _interopRequireWildcard = require("@babel/runtime-corejs2/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _assign = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/assign"));

var _routesFlippingTiles = _interopRequireDefault(require("../../src/common/components/DrupalTiles/config/routesFlippingTiles"));

var _routes = _interopRequireDefault(require("../../src/common/components/Equizzer/config/routes"));

var _config = _interopRequireDefault(require("../../config/config"));

var _EquizzerChapterCanOpenChecker = _interopRequireDefault(require("../../src/common/components/Equizzer/checks/EquizzerChapterCanOpenChecker"));

var _strings = require("../../config/strings");

var _ActivationCodeHelper = _interopRequireDefault(require("../../src/helpers/ActivationCodeHelper"));

var _CordovaHelper = require("../../src/helpers/CordovaHelper");

var _KESI2Helper = require("../../src/KESI2/KESI2Helper");

var _FeedbackService = require("../../src/KESI2/Services/FeedbackService");

var _MuiAlertHelper = require("../../src/helpers/MuiAlertHelper");

var _FeatureManager = _interopRequireDefault(require("../../src/utils/features/FeatureManager"));

var _DataStore = _interopRequireWildcard(require("../../src/KESI2/Stores/DataStore2"));

var kesiMasterFeatureName = 'kesi_master';

function openRegisterQdFlow() {
  _EquizzerChapterCanOpenChecker["default"].checkForQd();
}

function openActivationCodeDialog() {
  _ActivationCodeHelper["default"].activateForFeature((0, _strings.getString)('UNLOCK_MASTER_FUNCTIONALITIES_DIALOG_TEXT'), (0, _strings.getString)('UNLOCK_MASTER_FUNCTIONALITIES_DIALOG_TITLE'), kesiMasterFeatureName, true);
}

function removeFeature() {
  _FeatureManager["default"].removeFeature(kesiMasterFeatureName);
}

function isNotKesiMaster() {
  return !(0, _KESI2Helper.isKesiMaster)();
}

function deleteVoting() {
  var cancelButton = {
    label: (0, _strings.getString)('CANCEL')
  };
  var okButton = {
    label: (0, _strings.getString)('OK'),
    callback: function callback() {
      return (0, _FeedbackService.resetVotingResults)();
    }
  };
  (0, _MuiAlertHelper.showAlert)((0, _strings.getString)('RESET_VOTING_RESULTS_TEXT'), (0, _strings.getString)('RESET_VOTING_RESULTS'), true, [cancelButton, okButton]);
}

var _default = (0, _assign["default"])({}, _config["default"], {
  userSelectText: false,
  supportedLanguages: ['de'],
  renderExtraSettingsButtonAboveMainMenuItems: true,
  homeScrollEnabled: false,
  navbarFixed: true,
  showCounter: false,
  showBottomBar: false,
  targetTag: 'sanofi',
  rootId: 'KESI_SPOC_ROOT_SANOFI!1103',
  cordovaStartUrl: function cordovaStartUrl() {
    return 'kesi2home';
  },
  targetFeatures: [_routesFlippingTiles["default"], _routes["default"] // Feature2Routes,
  // Feature3Routes,
  ],
  additionalPreconditionActions: [{
    actionType: 'registerForQd',
    callback: openRegisterQdFlow
  }],
  extraSettingsItemsGetter: function extraSettingsItemsGetter() {
    return [{
      condition: isNotKesiMaster,
      label: (0, _strings.getString)('UNLOCK_MASTER_FUNCTIONALITIES'),
      callback: openActivationCodeDialog
    }, {
      condition: _KESI2Helper.isKesiMaster,
      label: (0, _strings.getString)('RESET_VOTING_RESULTS'),
      callback: deleteVoting
    }, // {label: getString('RESET_APP_TITLE'), callback: logOutWithConfirmation},
    {
      condition: _KESI2Helper.isKesiMaster,
      label: (0, _strings.getString)('REMOVE_MASTER_FUNCTIONALITIES'),
      callback: removeFeature
    }];
  },
  // eslint-disable-next-line
  isKesiMaster: false,
  // will be set by webpack
  // eslint-disable-next-line
  isKesiSlave: false,
  // will be set by webpack
  kesiMasterFeatureName: kesiMasterFeatureName,
  showLectureSidebar: false,
  showLectureHeader: true,
  showOnlyLectures: true,
  renderExtraSettingsButtonInNavbar: true,
  showHomeButton: false,
  showFilterFavoriteButton: true,
  showFav: true,
  showRead: true,
  sortByOrderField: true,
  sendNavigateMessages: true,
  usesAnalyzer: false,
  showSpocInfo: true,
  useCenteredButtons: true,
  loadVisibilities: true,
  defaultUser: 'mobile_equizzer',
  defaultPassword: '!Ye9((UUBpjeu6',
  authClient: 'equeo_client',
  authClientSecret: '4za(4rtDBnBF4FA72z&wMCU^K#VB4xR&',
  authScopes: 'eCloud:read logAggregate:read authUser:register',
  backendUseBearerAuth: false,
  logSendUrl: 'logentry',
  feedbackRetrieveUrl: 'feedbacks',
  usagesRetrieveUrl: 'usages',
  allContentRetrieveUrl: 'allcontent',
  defaultNavbarTitle: 'Verhalten im Reinraum',
  scaleOverlayTiles: 1.0,
  showBackInNavBarInsteadOfCloseButtonForNuggets: true,
  navbarLogo: require('./assets/logo-sanofi.png'),
  useNavbarLogoAsHomeButton: true,
  onlyUseActivationCode: true,
  useWebLogin: false,
  forcedSettingsIconColor: '#525BA3',
  redefineUserAgent: false,
  homeButtonWorksAsBackButton: true,
  defaultDataStoreValues: {
    'client.ably.enabled': true
  }
});

exports["default"] = _default;