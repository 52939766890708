"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _DataStore = require("../src/KESI2/Stores/DataStore2");

var _QRCode = require("../src/helpers/QRCode");

var _default = {
  /**
   * Please make sure not to define additionally config settings in the target configs without specifying a
   * default value here.
   *
   * Keep in mind that a component designed for a specific target today may be useful for another target tomorrow.
   */
  requiredFeature: null,
  trainerFeature: null,
  scrollToRefreshEnabled: false,
  menuScrollEnabled: false,
  homeScrollEnabled: false,
  nickNameRequired: false,
  showQuizProfile: false,
  navbarFixed: false,
  menuOpenOnStart: false,
  dataStorePathKey: 'equeo',

  /**
   * course types that can be interpreted and displayed in main menu
   * Remember to merge those here with target specific ones in target config. Example:
   *
   *    knownCourseTypes: []
   *      .concat(defaultConfig.knownCourseTypes)
   *      .concat([
   *        'extratype1',
   *        'extratype2',
   *     ]
   *    )
   */
  knownCourseTypes: ['about', 'push_notification', 'reset_app', 'external_website', 'equeo_external_website', 'applink', 'startall'],

  /**
   * course types that are interpreted (and not dismissed) but not displayed in main menu (normal items).
   * Remember to merge those here with target specific ones in target config. See knownCourseTypes for example.
   */
  hiddenCourseTypes: ['config', 'bottom_chapters', 'settings'],

  /**
   * course types that are passed from parent chapter to all child chapters
   */
  inheritingCourseTypes: [],

  /**
   * These types (only if not inherited) will be displayed immediately on the screen and the menu closes though the
   * menu is expanded there. This was introduced for NuggetChapters as they are kind of additional view for the MainMenu
   */
  chaptersOpenedInScreenAndMenu: [],
  hiddenCourseTypesOnActivatedFeature: {},

  /**
   * If set to true the user can touch text on any page, mark it and copy it.
   */
  userSelectText: true,

  /**
   * Lists all languages that string translations exist for. This is prioritized order.
   * If some strings do not exist in all languages, the languages earlier in this array are tried first.
   * Overwrite this setting in target config to only support a subset of languages (or other languages).
   */
  supportedLanguages: ['de'],

  /**
   * Renders an extra settings button in the (Quiz Profile) section above the main menu list entries
   * (this is where the back arrow is, too).
   *
   * The button will only be rendered if the course contains a chapter with type "settings".
   *
   * When the button is tapped, the chapter with type 'settings will be expanded and highlighted in main menu.
   */
  renderExtraSettingsButtonAboveMainMenuItems: true,
  renderExtraSettingsButtonInNavbar: false,

  /**
   * If renderExtraSettingsButtonAboveMainMenuItems is set true the settings page shows some default settings.
   * If the target needs to set extra settings, this is the place to set them.
   *
   * The array is expected to contain objects, such as
   *    { label: 'Extra settings item', callback: () => {} },
   *
   * Note: This element must be a function because any localized strings used here are not available at start!
   */
  extraSettingsItemsGetter: function extraSettingsItemsGetter() {
    return [];
  },

  /**
   *  In a normal app the first page of the root chapter is the start page
   *  With this settings targets can choose to have another start page. This setting is expected to be a string
   *  and should contain the chapter type to be opened at start. The first chapter with this type will be opened.
   *
   *  If no such chapter is available, the content will be updated. If then, still, no such chapter is available
   *  the default homepage will be shown.
   */
  startPageChapterType: null,

  /**
   *  A cordova app needs to
   */
  cordovaStartUrl: function cordovaStartUrl() {},

  /**
   * A target can add extra functionality to the basic functionality by adding additional routes.
   * They are passed as an array for convenient setting. Please see ERGO Quiz or Quizzer target config.jsx for examples
   */
  targetFeatures: [],

  /**
   * If this setting is set to true the menu will be draggable on phones and always open on tablets
   */
  menuAlwaysOpenOnWideScreens: true,

  /**
   * If this setting is set to true a counter with every user on this page will be shown on the navbar
   */
  showCounter: true,

  /**
   * If this setting is set to true a bottom bar will be shown. YOU STILL NEED TO SPECIFY WHICH ONE!
   */
  showBottomBar: false,

  /**
   * Decide whether the bottom bar should be visible always or only in tile view
   */
  showBottomBarOnlyInLecturesAndSpocs: false,

  /**
   * Show the ErvBottomBar. Set showBottomBar to true!
   */
  showErvBottomBar: false,

  /**
   * Set your custom bottom bar elements. Each element looks like the following object:
   *      {
   *        labelKey: <string available via getString>,
   *        icon:  require('myicon.png'),
   *        callback: <function>
   *      }
   */
  bottomBarElements: [],

  /**
   * This is the id of the root element
   */
  rootId: 'KESI_SPOC_ROOT',

  /**
   * This is the activation code to use (ie for gwg kesi)
   */
  activationCode: null,

  /**
   * If this setting is set to true a home button will be shown on the navbar (right side)
   */
  showHomeButton: false,

  /**
   * If this setting is set to true a home button will be shown on the navbar (left side)
   */
  showHomeButtonLeft: false,

  /**
   * If this setting is set to true all buttons will have only uppersize fonts
   */
  allButtonsUpperCase: false,

  /**
   * Until we got a real analyzer we sort
   */
  spocSortAlgorithm: function spocSortAlgorithm(spoc1, spoc2) {
    var id1 = Number(spoc1.id);
    var id2 = Number(spoc2.id);

    if (id1 < id2) {
      return -1;
    }

    if (id1 > id2) {
      return 1;
    }

    return 0;
  },

  /**
   * If true, the Navbar has a menu button and you can open it by sliding from the left
   */
  hasSlidingMenu: false,
  // the first one is really old and i dont think we should use that again
  showBackInNavBarInsteadOfCloseButtonForNuggets: false,
  showCloseButtonInsteadOfBackButtonInNavbar: false,
  showLectureSidebar: true,
  showLectureHeader: false,
  showHeaderInfos: true,
  showFilterFavoriteButton: false,
  showReloadButtonInNavbar: false,
  showGlossarButton: false,
  showFav: false,
  showRead: false,
  showSingleplayerLearnedAlert: false,
  setReadToStore: true,
  showSubtitleOnTile: true,
  showSubtitleOnHeader: false,
  showArrowOnTile: false,
  showArrowInListNugget: false,
  showOnlyLectures: false,
  showNavbarBrowserUserIcon: false,
  useUserIconInsteadOfChartIcon: false,
  useSettingsDialog: false,
  useCenteredButtons: false,
  centerLectureTitle: false,
  showTitleAtBottom: false,
  forceTitleColor: false,
  hideTitle: false,
  iconFarRight: null,

  /**
   * This title will be shown if a title is set that is equivalent to null
   */
  defaultNavbarTitle: '',
  titleIcon: null,
  forceTitleIcon: false,

  /**
   * This much space any overlay tiles will of the screen. If set to 1.00 the background is never shown
   */
  scaleOverlayTiles: 0.93,
  // eslint-disable-next-line
  isKesiMaster: false,
  // will be set by webpack
  // eslint-disable-next-line
  isKesiSlave: false,
  // will be set by webpack,
  kesiMasterFeatureName: null,
  kesiRevisionFeatureName: null,
  sendNavigateMessages: false,
  bottomLogo: null,
  navbarLogo: null,
  navbarLogoRight: null,
  tilesPageColor: 'white',
  // the color of the page behind the KESI2 tiles
  useKeywordAsColor: false,
  usesAnalyzer: true,
  // whether or not the analyzer from the server is online
  sortAlphabetically: false,
  sortById: false,
  sortByOrderField: false,
  imagePathWithCourseIdOrEntityId: 'entity',
  // 'course' (old app) or 'entity' (KESI apps)
  useNativeDeviceUuid: false,
  // if true, CordovaPlugin is called, otherwise Ably makes up a uuid
  defaultUser: null,
  // obviously given in target config only
  defaultPassword: null,
  //          - " -
  authClient: null,
  // obviously given in target config only
  authClientSecret: null,
  //          - " -
  authScopes: null,
  androidBackButtonDisabled: false,
  androidBackButtonLikeNavigationBarBackButton: false,
  hockeyAppIdAndroid: null,
  hockeyAppIdIos: null,
  storeDeepCopy: true,
  oneQuestionPerRound: false,
  // temporary fix for VRSF
  resizeQuestionImgForSmallDevices: true,
  absoluteVoteResults: false,
  votesViewOnly: false,
  showNuggetIcons: true,
  navigationBarImage: null,
  activationCodeWizardLogo: null,
  registerForPrivateAblyChannelWithDeviceUuid: true,
  forcedActivationCode: null,
  ablyDataSendUrl: '/rest/api/v1/deviceConfiguration',
  // url that setData and getData requests are answered to (see DataStoreAblyHelper)
  useAblyForOnlineDetermining: true,
  backendUseBearerAuth: false,
  logSendUrl: 'logentry',
  feedbackRetrieveUrl: 'feedbacks',
  likeRetrieveUrl: 'likes',
  useFeedbackService: true,
  usagesRetrieveUrl: 'usages',
  useUsageService: true,
  allContentRetrieveUrl: 'rest/v1/allcontent',
  filelistUrl: 'rest/v1/filelist',
  allContentPartUrl: 'rest/v1/allcontent/parts',
  feedbackEmailUrl: 'rest/v1/send',

  /**
   * Version number of scheme how DataStore is arranged. It's used by DataMigrator class
   * Do not change in target's config.js
   */
  dataSchemeVersion: 8,
  useHistoryBasedFeedback: false,
  // for QD
  defaultUseVibration: false,
  useWebLogin: false,
  // show kesi login for browser version
  useTileLogin: false,
  // login with Precondition when clicking a tile
  showForgetLinks: true,
  useSnomLinkId: false,
  useEurofhLinkId: false,
  useDeutscheWohnenId: false,
  loadVisibilities: false,
  // load tile visibilites (master/slave hiding)
  usesFlexMenu: false,
  additionalPreconditionActions: [],
  showSafetyShield: false,
  useKesiSpinner: false,
  getKesiSpinner: function getKesiSpinner() {
    return null;
  },
  showResetTest: false,
  quizStorePath: null,
  onlyUseActivationCode: false,
  activateWithPersonalNumber: false,
  useLoginGraphics: false,
  usesWebIcons: false,
  useTileZoom: false,
  showAllDoneDialog: false,
  onDataStorePublicDataChangeWebhook: null,
  // sends the complete public.* to this address via POST
  dataStoreServerUrl: null,
  // URL from where to fetch the data store values for public.*
  sendPublicDataStoreOnlyIfLoggedIn: false,
  saveToDateStoreWaitsForPersisting: false,
  // true to be very sure, false to speed things up
  isLoggedIn: function isLoggedIn() {},
  isSafetureApp: false,
  isSnooApp: false,
  isErgoApp: false,
  showUpArrow: false,
  showUpArrowAlwaysAsRead: false,
  useNewAlerts: false,
  onlyPortrait: false,
  showProgressDialogs: false,
  resetAppOn401: false,
  logOutOn403: false,
  userAgentAppName: null,
  // if not set, it is the displayed app name
  sendCertificate: false,
  sendCertificateOnUserRequest: false,
  multiLanguage: false,
  useLanguageParam: false,
  useCogSettingsIcon: true,
  showSpocInfo: false,
  showOnlyDrupalQuestionFeedback: false,
  clearStorageOnStart: false,
  logDrupalQuizAnswersToDataStore: false,
  showKesiWelcomeMessage: true,
  collectTrackingInformation: false,
  trackGeolocationForTrackingInformation: false,
  useHardcodedErvLogin: false,
  // remove this after the ITB
  resetDataStoreOnLoad: false,
  volatileDataStore: false,
  // DataStore will not be saved to local / native storage ...
  nonVolatileDataStorePath: ['private.application', 'public.features'],
  // ... except for these paths

  /**
   * these values are set to the DataStore when they are not existing. They will not be set if their value is null.
   */
  defaultDataStoreValues: {
    'client.ably.enabled': false
  },

  /**
   * these values are set to the DataStore at every start,
   */
  dataStoreValues: {},
  mockMessagesOnTile: false,
  showLectureHeaderIcon: true,
  showWarningsIconInNavbar: false,
  mustShowLoading: false,
  showLoadingTimeout: 0,
  onylOneWelcomeText: false,
  showFeedbackMail: false,
  feedbackMail: null,
  feedbackText: null,
  ablyApiKey: 'CuzkTg.KigbpQ:Xgie6yBgMXoH7wKb',
  attachToChannels: [],
  // add some target specific channels here
  attachToChannelsPresences: [],
  // add some target specific channels presence listeners here
  forcedSettingsIconColor: null,
  redefineUserAgent: true,
  homeButtonWorksAsBackButton: false,
  welcomeAlerts: null,
  // see erv target for concrete values
  showAuthError: true,
  allowEmptyStrings: false,
  useNavbarLogoAsHomeButton: false,
  forceReloadFromServer: false,
  // remove when caching works on ecloud
  loginComponent: null,
  useRequestCodeToDecideUserPath: false,
  showBccOptions: false,
  usesChatNuggets: false,
  usesLiveTickerNuggets: false,
  allowEnterAsSendInChat: false,
  targetSpecificInclusions: {
    FeatureManager: true,
    EquizzerRestService: true,
    DefaultSettingsPage: true
  },

  /**
   * This setting is used in Snoo. Only after having read or skipped or unlocked a lecture the next one is visible or after
   */
  showLecturesOneByOne: false,

  /**
   * If true shows lectures in reversed order. Used in Snoo
   */
  reverseLectureOrder: false,

  /**
   * If set to true the tiles will not change their position (left, right, middle).
   * The most upper left tile will get some padding and be centered if necessary.
   * This feature is used in Snoo
   */
  fillUpTileRows: false,

  /**
   * If false: Lectures set their own title
   * If true: Spoc title are kept in navigation bar even if lecture is opened
   */
  keepSpocTitles: false,

  /**
   * If set to true all nuggets in a lecture will be marked as read (thus the lecture itself, too) if the lecture was opened
   * - no matter if the nuggets were really in view
   */
  markOpenedLecturesAsCompletelyRead: false,
  showBadgeOnUnreadLectures: false,
  showQDZoomButton: false,
  tcCountryHelper: null,
  // only for erv travel & care app
  isDemo: false,
  replaceDrupalLinks: false,
  alwaysActivateAppsflyer: false,
  activateAppsflyerUntilReferred: false,
  appflyeriOSId: '123456789',
  // the apple appstore id
  appsflyerDevKey: 'S3oAab7TyLhC88t5orPt5h',
  bookmarkIconTrueNavBar: require('./assets/bookmark_true_navbar.svg'),
  bookmarkIconFalseNavBar: require('./assets/bookmark_false_navbar.svg'),
  bookmarkIconTrueLectureHeader: require('./assets/bookmark_true_lecture.svg'),
  bookmarkIconFalseLectureHeader: require('./assets/bookmark_false_lecture.svg'),
  bookmarkIconTrueTile: require('./assets/bookmark_true_tile.svg'),
  bookmarkIconFalseTile: require('./assets/bookmark_false_tile.svg'),
  arrowLeft: null,
  arrowRight: null,
  homeIcon: null,
  userIcon: null,
  likeActive: null,
  likeInactive: null,
  downloadIcon: null,
  errorIcon: null,
  deleteIcon: null,
  loadingIcon: null,
  showMinimalizedFeedbackIcon: false,
  useFavButtonAsRouteLink: false,
  tileBackgroundImage: null,
  tileBackgroundImageLight: null,
  qdWithoutMenu: false,
  // only for QD in browser, especially when embedded in Diginale app
  hideQDGroupInProfile: false,
  showStyledPointsInProfile: false,
  useNumberRegexForActivationCode: false,
  sortPlayersByLastName: false,

  /**
   * If set to true there is an additional layer of data in Drupal, so the root object has to display the course objects and not the spocs
   * If false: Like usual -> Spoc -> Lecture -> Nugget
   */
  usesCourses: false,

  /**
   * If there are courses (in non flipping tiles) they will be display as a layer of tiles (not in bottom bar like ERGO)
   * If false: Like usual Courses in Bottom Bar, Spocs and Lectures as Tiles
   */
  showCoursesAsTiles: false,
  doNotSetTileHeight: false,

  /**
   * If set to true users can "like" single nuggets
   * If false: no liking possible
   */
  hasLikeFunctionalityForNuggets: false,

  /**
   * If set to true users can "like" whole lectures
   * If false: no liking possible
   */
  hasLikeFunctionalityForLectures: false,

  /**
   * If set to true use icons defined in the target
   * If false: use fontawesome icons
   */
  useTargetIcons: false,

  /**
   * If set to true there will a button for user feedback after answering a question
   * If false: no button visible
   */
  showQDQuestionFeedbackButton: true,

  /**
   * If set to true the ecloud will only serve published content to the device
   * If false: all content (including unpublished -> used for old clients as well)
   */
  showOnlyPublishedContent: false,
  setSubTilesAsFavsWhenParentIsFav: false,
  multilineTabs: false,
  allowGamesOlderThan48h: false,
  hasCertificate: false,

  /**
   * If set to true navbar and titles in lecture will have the same color as the lecture tile
   * If false: standard behavior
   */
  useTileBackgroundColorInTile: false,

  /**
   * Should the user enter an email address when he/she registers for QD in KESI?
   * If yes, a token is sent to this email and needs
   */
  useEmailForQdRegistration: false,

  /**
   * If checked by some components, this is the minimum. If the used browser is below, a warning is shown
   */
  minBrowser: null,
  // minBrowser: {
  //   chrome: 57,
  //   edge: 0,
  //   ie: 11,
  //   firefox: 55,
  //   safari: 11,
  //   ios: 10
  // }

  /**
   * If true, show no title on the tile (might be on the background graphic).
   * False should be the default
   */
  useBackgroundImageTitle: false,

  /**
   * If true, the next and the previous nugget in a swipe nugget will also be rendered
   * If set to false, only the current nugget will be rendered (no bigger nuggets due to bigger prev/next nugget
   */
  prerenderNextSwipeNugget: true,
  addSafeAreaForNotch: false,
  showSwipeNavigationAtTheBottom: true,

  /**
   * If true, new erv design will be used
   * If set to false, use the old design
   */
  isNewDesign: false,

  /**
   * If true, the spocs will be shown in a big menu bar below the navbar
   * If set to false, the standard kesi design will be uses
   */
  showSpocsInExtraMenu: false,

  /**
   * If true, a file named "splashscreen.mp4" must be placed in the target folder
   */
  hasAnimatedSplashscreen: false,
  useMobileColumns: false,

  /**
   * If true, use target var loggedOutTitleIcon
   */
  useDifferentNavbarIconWhenLoggedOut: false,

  /**
   * If true, position nugget icons as if there is no title (used for deges)
   */
  showNuggetIconsAlwaysOff: false,

  /**
   * If true, use the alert helper to show the quizNugget Feedback
   * If false, use the overlay of the quizNugget component
   */
  useAlertsForQuizNugget: false,

  /**
   * If true, go Home when a push arrives (quick fix for I4.0 to end up on the home screen, when a push arrives)
   * If false, use standard behavior
   */
  goHomeOnPush: false,
  slideInPreconditionTiles: false,

  /**
   * If true, show the learning state in the user info
   * If false, hide it
   */
  showLearningStateInUserInfo: true,

  /**
   * If true, show the booked courses of the user
   * If false, hide them
   */
  showBookedCoursesInUserInfo: false,

  /**
   * If true, show the user data of the user
   * If false, hide them
   */
  showUserProfileInUserInfo: false,

  /**
   * A function that checks the data under DATA_STORE_PATHS.USER_PROFILE.
   * If every requiredData is available, it returns true and otherwise false.
   *
   * The function must not return a Promise
   */
  isUserProfileComplete: function isUserProfileComplete() {},

  /**
   * This key is used to decipher encrypted qr codes whose contents' start with 'equeo' prefix
   */
  qrCodeSymmetricKey: 'Auv4eam0tee3ahPh',

  /**
   * If true, kesi tiles wont be animated when opening in Internet Explorer (used for DEGES)
   * If false, there will be a normal animation
   */
  disableTileAnimationOnIE: false,

  /**
   * If true, the nugget icon will be shown in two different colors corresponding to the learning state
   * If false, the nugget icon will always be grey
   */
  useNuggetIconsForLearnState: false,

  /**
   * This is only here for development as long as Falk is on a cruise. He'll change it after he comes back.
   */
  useOldQuizObjectAnswerStyle: false,

  /**
   * If true, the title size will be zoomed with the tiles
   * If false, title size will be the same (unaffected by tile zoom)
   */
  useZoomForTileFront: true,
  isDegesWebApp: false,
  showProgressBar: false,
  enableChatWithoutExpert: false,
  downloadBeforeShowingAlert: false,
  updateContentWithoutAsking: false,
  ignoreRouteForUpdateOnAppResume: false,

  /**
   * show qr code in settings screen
   */
  qrCodeLogin: false,
  qrCodeReferrer: false,
  // use this to disable the settings menu entry
  hasQRCodeScannerInSettings: false,
  allowPinchInLecture: false,
  useNewDownloadFunctionalityWithoutMD5: false,
  useSimpleTileAnimation: false,
  highlightBothPlayersInOverview: false,
  showOnlyQuestionOverlay: false,
  useDrupalContent: false,
  showSubjectsInMenu: false,
  showFeedbackOverAnswers: false,
  allowDifferentQuestionBoxSizes: false,
  doNotShowSendButtonForMCSC: false,
  hideRobotsInHighscore: false,
  useErgoFeedbackStyle: false,
  sendSinglePlayerQuestionsAsBulk: false,
  sortMCFeedback: false,
  nextQuestionOnButtonOnly: false,
  showUpdateWindow: false,
  useLoginMailForGWGCert: false,
  goToQuizHomeAfterTappingPush: false,
  showQuizModeNames: true,
  addZoomClickToImages: true,
  showRightContainerAtTheBottom: false,
  addLecturePadding: true,
  useNewMCSCStyling: true,
  useNewScoreStyle: true,
  hideTitleInBottomBar: false,
  showJumpButtons: true,
  showScrollBar: false,
  isInScorm: false,
  translateDrupalIds: false,
  forceLikertNuggetDesktopVersion: false,
  prerenderNextWizardNugget: false,
  useLandscapeTiles: false,
  translateDrupalIdFn: function translateDrupalIdFn(id) {
    return id;
  },
  showProgressBarNextToTiles: false,
  useKeywordForContentGroups: false,
  shrinkTileGroups: false,
  useQDinBase: false,
  useNewLikertDesign: false,
  showDayInfoOnTile: false,
  statsIcon: require('./assets/stats.svg'),
  singlePlayerIcon: require('./assets/singleplayer.svg'),
  battleIcon: require('./assets/battle.svg'),
  playIcon: require('./assets/quiz-starten.png'),
  useScrollIndicator: true,
  // scrollIndicator for long Questions/Answers in QD
  startDatePath: _DataStore.DATA_STORE_PATHS.BASE_START_DATE,
  useBackgroundColorNotForWholeTile: false,
  showQuizGroupNameInNavbar: false,
  useOpacityInsteadOfGreyedOut: false,
  centerPodcastPlayButtonOverInfo: false,
  disableQuizPolling: false,
  useArrowsInsteadOfButtonsInWizardNugget: false,
  scaleTileOverBottomBar: false,
  useOnlyTargetSettings: false,
  showExtraAudioIconsInsteadOfControls: false,
  allowPodcastSubscriptions: false,
  showSwipeImagePreview: false,
  addArrowToTileTitle: false,
  addSubtitleToTitleField: false,
  hideGradientInShield: false,
  showZoomIcon: false,
  renderProgressBarOnTile: false,
  showNuggetDivider: true,
  baseRoute: 'kesi2',
  showInfoAfterLastQuizQuestion: false,
  targetSpecificDataStorePath: 'public.base',
  showFeedbackIcon: false,
  hideNickNameInLogin: false,
  requiresActivationCodeInUrl: false,
  useLoginBeforeUserPage: false,
  storeSeenToContentStore: false,
  showPodcastSkip10Button: true,
  useNavBarColorInSafeAreaBottom: false,
  videosDownloadable: true,
  setContentsAsSeen: false,
  audioPlayerShowTitle: false,
  // Show title in Audio Player, mainly used for DrupalPodcastNugget
  goHomeAfterSendingFormNugget: true,
  forceWebViewOpening: false,
  showJumpToTopExplanation: true,
  // Show "jump to top explanation" at the end of a lecture, if showUpArrow enabled
  NuggetFrontInjection: function NuggetFrontInjection() {
    return null;
  },
  // inject component into the bottom left corner of a NuggetFront,
  hideFlippedTileBackground: false,
  showLikertOptionIcons: false,
  forceDefaultTitle: false,
  scanQRCodeFn: function scanQRCodeFn() {
    return (0, _QRCode.scanQrCode)();
  },
  availableForScreenReader: false,
  askForDetailedRecruitingInfos: false,
  ergoChatDisabled: false,
  allowErgoChatImages: true,
  hideBottomBarInWebView: false
};
exports["default"] = _default;