"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.sendDirtyRoundScores = exports.getLevelHighscores = exports.getGameHighscore = exports.getUser = exports.getQuestions = exports.getDataObjects = exports.getRounds = exports.getCurrentLevel = exports.getLevel = exports.getGame = exports.replaceLevelHighscoreList = exports.setRoundPlayed = exports.saveDataObjectLocally = exports.getRoundPlayed = exports.postRoundScore = exports.mayStart = exports.DATA_OBJECT_ROUND_PLAYED_INTERNAL = exports.DATA_OBJECT_TYPE_LEVEL_HIGHSCORE = exports.DATA_OBJECT_TYPE_GAME_HIGHSCORE = exports.DATA_OBJECT_TYPE_QUESTION = exports.DATA_OBJECT_TYPE_ROUND_SCORE = exports.DATA_OBJECT_TYPE_ROUND = exports.DATA_OBJECT_TYPE_LEVEL = exports.DATA_OBJECT_TYPE_GAME = exports.FEATURE_NAME = void 0;

var _stringify = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/json/stringify"));

var _CordovaHelper = require("../../../../helpers/CordovaHelper");

var FEATURE_NAME = 'ergoquiz';
exports.FEATURE_NAME = FEATURE_NAME;
var DATA_OBJECT_TYPE_GAME = 'QuizGame';
exports.DATA_OBJECT_TYPE_GAME = DATA_OBJECT_TYPE_GAME;
var DATA_OBJECT_TYPE_LEVEL = 'QuizLevel';
exports.DATA_OBJECT_TYPE_LEVEL = DATA_OBJECT_TYPE_LEVEL;
var DATA_OBJECT_TYPE_ROUND = 'QuizRound';
exports.DATA_OBJECT_TYPE_ROUND = DATA_OBJECT_TYPE_ROUND;
var DATA_OBJECT_TYPE_ROUND_SCORE = 'QuizRoundScore';
exports.DATA_OBJECT_TYPE_ROUND_SCORE = DATA_OBJECT_TYPE_ROUND_SCORE;
var DATA_OBJECT_TYPE_QUESTION = 'QuizQuestion';
exports.DATA_OBJECT_TYPE_QUESTION = DATA_OBJECT_TYPE_QUESTION;
var DATA_OBJECT_TYPE_GAME_HIGHSCORE = 'QuizGameHighscore';
exports.DATA_OBJECT_TYPE_GAME_HIGHSCORE = DATA_OBJECT_TYPE_GAME_HIGHSCORE;
var DATA_OBJECT_TYPE_LEVEL_HIGHSCORE = 'QuizLevelHighscore';
exports.DATA_OBJECT_TYPE_LEVEL_HIGHSCORE = DATA_OBJECT_TYPE_LEVEL_HIGHSCORE;
var DATA_OBJECT_ROUND_PLAYED_INTERNAL = 'ErgoQuizRoundPlayed_INTERNAL';
exports.DATA_OBJECT_ROUND_PLAYED_INTERNAL = DATA_OBJECT_ROUND_PLAYED_INTERNAL;

var mayStart = function mayStart() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'mayStart', []);
};

exports.mayStart = mayStart;

var postRoundScore = function postRoundScore(roundId, points, passed) {
  console.log("sending round score for round ".concat(roundId, " with points ").concat(points));
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'postDataObject', [{
    dataObject: {
      type: DATA_OBJECT_TYPE_ROUND_SCORE,
      value: {
        round: roundId,
        points: points,
        passed: passed
      },
      feature: FEATURE_NAME
    },
    feature: FEATURE_NAME
  }]);
};

exports.postRoundScore = postRoundScore;

var getRoundPlayed = function getRoundPlayed(roundId) {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectsByTypeAndConditions', [{
    type: DATA_OBJECT_ROUND_PLAYED_INTERNAL,
    conditions: [{
      conditionType: 'stringComparison',
      field: 'roundId',
      comparator: '==',
      value: roundId
    }],
    feature: FEATURE_NAME
  }]);
};

exports.getRoundPlayed = getRoundPlayed;

var saveDataObjectLocally = function saveDataObjectLocally(dataObject) {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'saveDataObjectLocally', [{
    dataObject: dataObject,
    feature: FEATURE_NAME
  }]);
};

exports.saveDataObjectLocally = saveDataObjectLocally;

var setRoundPlayed = function setRoundPlayed(roundId) {
  return saveDataObjectLocally({
    type: DATA_OBJECT_ROUND_PLAYED_INTERNAL,
    feature: FEATURE_NAME,
    value: {
      roundId: roundId,
      played: true
    }
  });
};

exports.setRoundPlayed = setRoundPlayed;

var replaceLevelHighscoreList = function replaceLevelHighscoreList(highscore) {
  return saveDataObjectLocally(highscore);
};

exports.replaceLevelHighscoreList = replaceLevelHighscoreList;

var getGame = function getGame() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectByType', [{
    type: DATA_OBJECT_TYPE_GAME,
    // requestCondition: {
    //   type: DATA_OBJECT_TYPE_GAME,
    //   feature: FEATURE_NAME,
    // },
    feature: FEATURE_NAME
  }]);
};

exports.getGame = getGame;

var getLevel = function getLevel(levelId) {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectById', [{
    id: levelId,
    feature: FEATURE_NAME
  }]);
};

exports.getLevel = getLevel;

var getCurrentLevel = function getCurrentLevel(user) {
  var now = new Date().toISOString();
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectsByTypeAndConditions', [{
    type: DATA_OBJECT_TYPE_LEVEL,
    expectedCount: 1,
    forceRequest: true,
    conditions: [{
      conditionType: 'stringComparison',
      field: 'openFrom',
      comparator: '<=',
      value: now
    }, {
      conditionType: 'stringComparison',
      field: 'openUntil',
      comparator: '>=',
      value: now
    }, {
      conditionType: 'stringComparison',
      field: 'playgroup',
      comparator: '==',
      value: user.groupName
    }],
    // requestCondition: {
    //   type: DATA_OBJECT_TYPE_LEVEL,
    //   feature: FEATURE_NAME,
    // },
    feature: FEATURE_NAME
  }]);
};

exports.getCurrentLevel = getCurrentLevel;

var getRounds = function getRounds(levelId, start, limit) {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectsByTypeAndConditions', [{
    type: DATA_OBJECT_TYPE_ROUND,
    expectedCount: limit,
    conditions: [{
      conditionType: 'stringComparison',
      field: 'level',
      comparator: '==',
      value: levelId
    }, {
      conditionType: 'intComparison',
      field: 'number',
      comparator: '>=',
      value: start
    }, {
      conditionType: 'intComparison',
      field: 'number',
      comparator: '<',
      value: start + limit
    }],
    requestCondition: {
      type: DATA_OBJECT_TYPE_ROUND,
      start: start,
      limit: limit,
      order: 'asc',
      orderBy: 'number',
      condition: "level = '".concat(levelId, "'"),
      feature: FEATURE_NAME
    },
    feature: FEATURE_NAME
  }]);
};

exports.getRounds = getRounds;

var getDataObjects = function getDataObjects(emsIds, requestCondition) {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectsByIds', [{
    type: DATA_OBJECT_TYPE_QUESTION,
    expectedCount: emsIds.length,
    ids: emsIds,
    requestCondition: requestCondition,
    feature: FEATURE_NAME
  }]);
};

exports.getDataObjects = getDataObjects;

var getQuestions = function getQuestions(questionIds) {
  var requestCondition = {
    type: DATA_OBJECT_TYPE_QUESTION,
    condition: "emsId IN ".concat((0, _stringify["default"])(questionIds).replace(/"/g, "'")),
    feature: FEATURE_NAME
  };
  return getDataObjects(questionIds, requestCondition);
};

exports.getQuestions = getQuestions;

var getUser = function getUser() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_ERGO_QUIZ, 'getUser', []);
};

exports.getUser = getUser;

var getGameHighscore = function getGameHighscore() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectByType', [{
    type: DATA_OBJECT_TYPE_GAME_HIGHSCORE,
    forceRequest: true,
    // requestCondition: {
    //   type: DATA_OBJECT_TYPE_GAME_HIGHSCORE,
    //   feature: FEATURE_NAME,
    // },
    feature: FEATURE_NAME
  }]);
};

exports.getGameHighscore = getGameHighscore;

var getLevelHighscores = function getLevelHighscores() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'getDataObjectsByType', [{
    type: DATA_OBJECT_TYPE_LEVEL_HIGHSCORE,
    forceRequest: true,
    // requestCondition: {
    //   type: DATA_OBJECT_TYPE_LEVEL_HIGHSCORE,
    //   feature: FEATURE_NAME,
    // },
    feature: FEATURE_NAME
  }]);
}; // export const getLevelHighscore = (levelId) => {
//   return getPromise(PLUGIN_DATA_OBJECTS, 'getDataObjectsById', [
//     {
//       type: DATA_OBJECT_TYPE_LEVEL_HIGHSCORE,
//       id: `${levelId}SCORE`,
//       forceRequest: true,
//       requestCondition: {
//         type: DATA_OBJECT_TYPE_LEVEL_HIGHSCORE,
//         feature: FEATURE_NAME,
//       },
//       feature: FEATURE_NAME,
//     },
//   ]);
// };


exports.getLevelHighscores = getLevelHighscores;

var sendDirtyRoundScores = function sendDirtyRoundScores() {
  return (0, _CordovaHelper.getPromise)(_CordovaHelper.PLUGIN_DATA_OBJECTS, 'sendDirtyDataObjects', [{
    type: DATA_OBJECT_TYPE_ROUND_SCORE,
    feature: FEATURE_NAME
  }]);
};

exports.sendDirtyRoundScores = sendDirtyRoundScores;