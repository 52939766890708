"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = _default;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/slicedToArray"));

var _constants = require("../definitions/constants");

var _react = require("react");

var _useInterval = require("./useInterval");

var _ArrayHelper = require("../../../../helpers/ArrayHelper.js");

var _usePreconditionChecker = _interopRequireDefault(require("./usePreconditionChecker"));

function _default(props) {
  var _useState = (0, _react.useState)([]),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      readySubDialogs = _useState2[0],
      setReadySubDialogs = _useState2[1];

  var _usePreconditionCheck = (0, _usePreconditionChecker["default"])({}),
      getFailingPrecondition = _usePreconditionCheck.getFailingPrecondition;

  (0, _useInterval.useInterval)(function () {
    var tempReadySubDialogs = [];
    props.subDialogs.forEach(function (dialog) {
      dialog.preconditions.forEach(function (precondition) {
        if (precondition.type === _constants.PRECONDITION_TYPE.SHOW) {
          if (!getFailingPrecondition(precondition)) {
            tempReadySubDialogs.push(dialog);
          }
        }
      });
    });

    if (!(0, _ArrayHelper.shallowEqual)(readySubDialogs, tempReadySubDialogs)) {
      setReadySubDialogs(tempReadySubDialogs);
    }
  }, 1000);
  return {
    readySubDialogs: readySubDialogs
  };
}