"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.validateDataObject = void 0;

var _ajv = _interopRequireDefault(require("ajv"));

// json scheme validator

/**
 * Only validates the dataObject.value part. The scheme must only handle that part
 */
var validateDataObject = function validateDataObject(dataObject, jsonSchema) {
  if (!jsonSchema) {
    console.error('No json schema given in validateDataObject');
    return true;
  }

  if (!dataObject || !dataObject.value) {
    console.error('No dataObject given to validateDataObject or dataObject has no .value part');
    return true;
  }

  var validate = new _ajv["default"]().compile(jsonSchema);
  var valid = validate(dataObject.value);

  if (!valid) {
    var randomValue = Math.round(Math.random() * 100);
    console.error("[id: ".concat(randomValue, "] Data Object does not match the schema. For more info read next line with same id"));
    console.error({
      id: randomValue,
      errorMessage: validate.errors,
      dataObject: dataObject,
      jsonSchema: jsonSchema
    });
  }

  return true;
};

exports.validateDataObject = validateDataObject;