"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var css = {
  'colors': {
    'titleBackgroundOverlay': 'rgba(255, 255, 255, 0.5)',
    'tileFontColor': '#525BA3',
    'tileFontWeight': '300',
    'tileBackgroundColor': '#FFFFFF',
    'navbarBackground': '#FFFFFF',
    'navbarBorderBottom': '1px solid #9b9b9b',
    'navbarText': '#525BA3',
    'menuBackground': '#FFFFFF',
    'buttonBorder': '#525BA3',
    'buttonBorderSecondary': '#525BA3',
    'buttonCaption': '#FFFFFF',
    'buttonCaptionSecondary': '#FFFFFF',
    'buttonBackground': '#525BA3',
    'buttonBackgroundLight': '#c6defa',
    'buttonBorderLight': '#8faed6',
    'buttonCaptionLight': '#8faed6',
    'buttonBackgroundDark': '#f4f4f4',
    'buttonBorderDark': '#f4f4f4',
    'buttonCaptionDark': '#525BA3',
    'lightText': '#525BA3',
    'textfieldCaption': '#525BA3',
    'tab': '#525BA3',
    'tabBackground': '#FFFFFF',
    'tableDivider': '#D8D8D8',
    'dialogText': '#6f6760',
    'dialogDivider': '#a69b8d',
    'dialogBackground': '#fbf9f7',
    'primaryColor1': '#ff2337',
    'primaryColor2': '#e32233',
    'navbarItemColor': '#525BA3',
    'lectureHeaderText': '#ffffff',
    'quizBackground': 'linear-gradient(#9cb9db,#c6defa)',
    'quizMainColor': '#525BA3',
    'quizBorderColor': '#525BA3',
    'quizSecondColor': '#c6defa',
    'quizThirdColor': '#8faed6',
    'quizText': '#FFFFFF',
    'quizTimer': '#525BA3',
    'homeBackground': '#525BA3',
    'highScoreUserColor': '#525BA3',
    'highScoreOpponentColor': '#FFFFFF',
    'menuItemHoverBackgroundColor': 'rgba(0,0,0,0)',
    'mainTextColor': '#FFFFFF',
    'questionHeader': '#bfb7ad',
    'questionHeaderText': '#6a625a',
    'questionHeaderPointsText': '#a69b8d',
    'questionTimer': '#525BA3',
    'questionText': '#FFFFFF',
    'answerDivider': '#d8d8d8',
    'roundFeedbackHeaderText': '#FFFFFF',
    'roundFeedbackDetailsText': '#6f6760',
    'correct': '#7ed321',
    'correctButNotChosen': '#696158',
    'wrong': '#d0011b',
    'opponentAnswer': '#878787'
  },
  'fontFamily': 'Lato',
  'navBarMaxWidth': '45%',
  'navBarContainerLeftTop': '20px',
  'navBarHeight': 64,
  'navBarHeightPx': '64px',
  'navBarLogoMarginTop': 10,
  'navBarRightSideButtonsTop': '30px',
  'navBarLogoLeft': '9%',
  'navBarLogoHeight': '35px',
  'navBarLogoTop': '20px',
  'thresholdScreenWidth': '800px',
  'mainMenuWidth': '300px',
  'navBarMargin': '0 40px',
  'navBarMaxHeight': '60px',
  'navBarTextAlign': 'center',
  'navBarVerticalAlign': 'middle',
  'navBarFontSize': '16px',
  'navBarFontWeight': 'bold',
  'navbarTitleColor': '#525BA3',
  'navBarPaddingTop': '14px',
  'navBarLineHeight': '19px',
  'burgerIconLeft': '10px',
  'navBarOverflow': 'hidden',
  'headerMinHeight': '200px',
  'spocInfoHeight': 40,
  'spocInfoHeightPx': '40px',
  'tiles': {
    'backgroundHeight': 120,
    'minTitleHeight': 121,
    'titleSize': 16,
    'iconSize': 80,
    'iconRight': '50%',
    'iconTransform': 'translateX(50%)',
    'backgroundColor': 'rgba(255,255,255,0.5)',
    'blur': 'blur(10px)'
  }
};
var _default = css;
exports["default"] = _default;