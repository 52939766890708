"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _reflux = _interopRequireDefault(require("reflux"));

/**
 * Created by falknisius on 12.01.17.
 */
function QuizActions() {
  return _reflux["default"].createActions(['addLevelPoints', 'setUser', 'setCurrentLevel', 'initStore', 'setLevelHighscores', 'setGameHighscores', 'updateScores']);
} // noinspection Eslint


module.exports = window.QuizActions = new QuizActions(); // eslint-disable-line