"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.register = register;
exports.unregister = unregister;

var _BuildConstants = require("../../../utils/BuildConstants");

var _MessagingService = _interopRequireDefault(require("../MessagingService"));

var _config = _interopRequireDefault(require("../../../../targets/cord_sanofi/config.js"));

/**
 * Created by falknisius on 15.06.17.
 */
// const NAVIGATION_CHANNEL = `${ABLY_CHANNEL_PREFIX}${target.targetTag}:REMOTECONTROL`
// init channel
// messageService.getChannel(NAVIGATION_CHANNEL)
var getNavigationChannelName = function getNavigationChannelName() {
  console.log('REMOTECONTROLLEDSERVICE', _config["default"]);
  return "".concat(_BuildConstants.ABLY_CHANNEL_PREFIX).concat(_config["default"].targetTag, ":REMOTECONTROL");
};

function register(onNavigateCallback) {
  _MessagingService["default"].registerChannel(getNavigationChannelName(), onNavigateCallback);
}

function unregister(onNavigateCallback) {
  _MessagingService["default"].unregisterChannel(getNavigationChannelName(), onNavigateCallback);
}