"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var _default = {
  noMoreRoundsText: 'Es sind im Augenblick keine unbeantworteten Fragen auf Ihrem Gerät vorhanden.<br /><br />Bitte gehen Sie online und starten Sie das Quiz erneut aus dem Menü.',
  gameNotLoadedMessage: 'Das aktuelle Spiel konnte nicht geladen werden.<br /><br />Bitte probieren Sie es später nocheinmal.'
};
exports["default"] = _default;