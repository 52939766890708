"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.getOverlayIconStyles = exports.getLoggedInUserStyle = exports.getStyles = void 0;

var _ColorHelper = require("../../../../helpers/ColorHelper");

var _ObjectHelper = require("../../../../helpers/ObjectHelper");

var _DeviceInfoHelper = _interopRequireDefault(require("../../../../helpers/DeviceInfoHelper"));

var _config = _interopRequireDefault(require("../../../../../targets/cord_sanofi/config.js"));

var getStyles = function getStyles(componentState) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var wrongStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewWrongIcon', defaultTheme, 'overviewWrongIcon', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK)),
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewWrongBackground', defaultTheme, 'overviewWrongBackground', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizSecondColor', defaultTheme, 'quizSecondColor', _ColorHelper.COLOR.WHITE))
  };
  var correctStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewCorrectIcon', defaultTheme, 'overviewCorrectIcon', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK)),
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewCorrectBackground', defaultTheme, 'overviewCorrectBackground', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE))
  };
  var neutralStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewNeutralIcon', defaultTheme, 'overviewNeutralIcon', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizSecondColor', defaultTheme, 'quizSecondColor', _ColorHelper.COLOR.WHITE)),
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewNeutralBackground', defaultTheme, 'overviewNeutralBackground', (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.WHITE))
  };
  var opponentAnswerStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'opponentAnswer', defaultTheme, 'opponentAnswer', _ColorHelper.COLOR.WHITE)
  };
  var scoreStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK),
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'buttonCaption', defaultTheme, 'buttonCaption', _ColorHelper.COLOR.WHITE)
  };
  var userNameStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)
  };
  var backButtonStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'buttonCaption', defaultTheme, 'buttonCaption', _ColorHelper.COLOR.WHITE),
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)
  };
  var categoryStyle = {
    color: "".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizText', defaultTheme, 'quizText', _ColorHelper.COLOR.WHITE), " !important"),
    backgroundColor: "".concat((0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'answerBoxColor', defaultTheme, 'answerBoxColor', _ColorHelper.COLOR.BLACK), " !important")
  };
  var midColumnStyle = {
    color: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'quizMainColor', defaultTheme, 'quizMainColor', _ColorHelper.COLOR.BLACK)
  };
  return {
    wrongStyle: wrongStyle,
    correctStyle: correctStyle,
    neutralStyle: neutralStyle,
    opponentAnswerStyle: opponentAnswerStyle,
    scoreStyle: scoreStyle,
    userNameStyle: userNameStyle,
    midColumnStyle: midColumnStyle,
    backButtonStyle: backButtonStyle,
    categoryStyle: categoryStyle
  };
};

exports.getStyles = getStyles;

var getLoggedInUserStyle = function getLoggedInUserStyle(componentState, playerState) {
  var index = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.quiz ? componentState.quiz["default"] : null;
  var userStyle = {};

  if (componentState.playerState === playerState || _config["default"].highlightBothPlayersInOverview) {
    userStyle = {
      backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'overviewPlayerHighlight', defaultTheme, 'overviewPlayerHighlight', 'rgba(255,255,255,0.14)'),
      paddingBottom: '50px'
    };
  }

  if (index === 4) {
    userStyle.borderRadius = '0px 0px 5px 5px';
    userStyle.paddingBottom = '10px';
  }

  if (index === null) {
    userStyle.borderRadius = '5px 5px 0px 0px';
    userStyle.paddingBottom = _DeviceInfoHelper["default"].isBrowser() ? '20px' : '0';
  }

  return {
    userStyle: userStyle
  };
};

exports.getLoggedInUserStyle = getLoggedInUserStyle;

var getOverlayIconStyles = function getOverlayIconStyles(componentState) {
  var theme = null;

  if (componentState.quiz && componentState.currentTheme in componentState.quiz) {
    theme = componentState.quiz[componentState.currentTheme];
  }

  var defaultTheme = componentState.navbar ? componentState.quiz["default"] : null;
  var wrongStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'wrong', defaultTheme, 'wrong', _ColorHelper.COLOR.BLACK)
  };
  var correctStyle = {
    backgroundColor: (0, _ObjectHelper.getObjectValueWithFallbacks)(theme, 'correct', defaultTheme, 'correct', _ColorHelper.COLOR.BLACK)
  };
  return {
    wrong: wrongStyle,
    correct: correctStyle
  };
};

exports.getOverlayIconStyles = getOverlayIconStyles;