"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.useInterval = useInterval;

var _react = require("react");

//why: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  var savedCallback = (0, _react.useRef)(function () {});
  (0, _react.useEffect)(function () {
    savedCallback.current = callback;
  }, [callback]);
  (0, _react.useEffect)(function () {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      var id = window.setInterval(tick, delay);
      return function () {
        return window.clearInterval(id);
      };
    }
  }, [delay]);
}