"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports.PRECONDITION_TYPE = exports.DIALOG_TYPES = exports.ACTION_TYPES = void 0;

/*
export enum CONDITION_TYPE {
  RENDER,
  ACTION
}
*
 */
var ACTION_TYPES;
exports.ACTION_TYPES = ACTION_TYPES;

(function (ACTION_TYPES) {
  ACTION_TYPES[ACTION_TYPES["NONE"] = 0] = "NONE";
  ACTION_TYPES[ACTION_TYPES["TEST"] = 1] = "TEST";
})(ACTION_TYPES || (exports.ACTION_TYPES = ACTION_TYPES = {}));

var DIALOG_TYPES;
exports.DIALOG_TYPES = DIALOG_TYPES;

(function (DIALOG_TYPES) {
  DIALOG_TYPES[DIALOG_TYPES["INITIAL"] = 0] = "INITIAL";
  DIALOG_TYPES[DIALOG_TYPES["PROMPT"] = 1] = "PROMPT";
  DIALOG_TYPES[DIALOG_TYPES["INTERACTION"] = 2] = "INTERACTION";
  DIALOG_TYPES[DIALOG_TYPES["FEEDBACK"] = 3] = "FEEDBACK";
  DIALOG_TYPES[DIALOG_TYPES["TIMER"] = 4] = "TIMER";
  DIALOG_TYPES[DIALOG_TYPES["QUESTION"] = 5] = "QUESTION";
  DIALOG_TYPES[DIALOG_TYPES["SERIES"] = 6] = "SERIES";
  DIALOG_TYPES[DIALOG_TYPES["ANSWER"] = 7] = "ANSWER";
})(DIALOG_TYPES || (exports.DIALOG_TYPES = DIALOG_TYPES = {}));

var PRECONDITION_TYPE;
exports.PRECONDITION_TYPE = PRECONDITION_TYPE;

(function (PRECONDITION_TYPE) {
  PRECONDITION_TYPE[PRECONDITION_TYPE["SHOW"] = 0] = "SHOW";
})(PRECONDITION_TYPE || (exports.PRECONDITION_TYPE = PRECONDITION_TYPE = {}));