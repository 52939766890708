"use strict";

var _interopRequireDefault = require("@babel/runtime-corejs2/helpers/interopRequireDefault");

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;

var _keys = _interopRequireDefault(require("@babel/runtime-corejs2/core-js/object/keys"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime-corejs2/helpers/typeof"));

var _RoundService = _interopRequireDefault(require("./RoundService"));

var _QuestionService = _interopRequireDefault(require("./QuestionService"));

var _CordovaHelper = require("../../../../helpers/CordovaHelper");

var POINTS_WORD_SINGULAR = 'Punkt';
var POINTS_WORD_PLURAL = 'Punkte';
var LOG_CONTEXT = 'ergoquiz';
var _default = {
  MULTIPLE_CHOICE_KEY: 'MultipleChoice',
  USER_ID: '',
  getAnsweringTimeAndPoints: function getAnsweringTimeAndPoints(question) {
    var points = question.points;
    var time = question.duration;
    return [time, points];
  },
  getPoints: function getPoints(question) {
    return question.points;
  },
  getPointsWord: function getPointsWord(points) {
    if (points === 1) {
      return POINTS_WORD_SINGULAR;
    }

    return POINTS_WORD_PLURAL;
  },
  getQuestion: function getQuestion(setId, roundNumber, questionNumber) {
    return _QuestionService["default"].get(_RoundService["default"].getByLevelAndNumber(setId, roundNumber).questions[questionNumber]);
  },
  shuffleArray: function shuffleArray(array) {
    var counter = array.length;
    var arrayToReturn = array;

    while (counter > 0) {
      var index = Math.floor(Math.random() * counter);
      counter--;
      var temp = array[counter];
      arrayToReturn[counter] = array[index];
      arrayToReturn[index] = temp;
    }

    return arrayToReturn;
  },
  clone: function clone(object) {
    if (!object || (0, _typeof2["default"])(object) !== 'object') {
      return object;
    }

    if (object instanceof Date) {
      return new Date(object.getTime());
    }

    if (object instanceof RegExp) {
      var pattern = object.source;
      var flags = "".concat(object.global ? 'g' : '').concat(object.ignoreCase ? 'i' : '').concat(object.multiline ? 'm' : '').concat(object.sticky ? 'y' : '');
      return new RegExp(pattern, flags);
    }

    var newObject = {};
    (0, _keys["default"])(object).forEach(function (key) {
      newObject[key] = object[key];
    });
    /* for (const key in object) {
      newObject[key] = object[key];
    } */

    return newObject;
  },
  postHomeScreenViewed: function postHomeScreenViewed(timeToRead) {
    var type = 'QuizHome';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      usedTimeToViewScreen: (timeToRead / 1000).toFixed(2)
    });
  },
  postQuizGameHighscoreScreenViewed: function postQuizGameHighscoreScreenViewed() {
    var type = 'QuizGameHighscore';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      action: 'view opened'
    });
  },
  postQuizLevelHighscoreScreenViewed: function postQuizLevelHighscoreScreenViewed() {
    var type = 'QuizLevelHighscore';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      action: 'view opened'
    });
  },
  postQuestionSolvedLogEntry: function postQuestionSolvedLogEntry(questionId, roundId, usedTimeToReadQuestion, usedTimeToAnswer, answers) {
    var type = 'QuestionLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      questionId: questionId,
      round: roundId,
      usedTimeToReadQuestion: (usedTimeToReadQuestion / 1000).toFixed(2),
      usedTimeToAnswer: (usedTimeToAnswer / 1000).toFixed(2),
      answers: answers,
      timeout: false
    });
  },
  postQuestionTimeoutLogEntry: function postQuestionTimeoutLogEntry(questionId, roundId, usedTimeToReadQuestion, answers) {
    var type = 'TimeoutQuestionLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      questionId: questionId,
      round: roundId,
      usedTimeToReadQuestion: (usedTimeToReadQuestion / 1000).toFixed(2),
      answers: answers,
      timeout: true
    });
  },
  postQuestionSolutionLogEntry: function postQuestionSolutionLogEntry(questionId, roundId, usedTimeToReadSolution) {
    var type = 'QuestionSolutionLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      questionId: questionId,
      round: roundId,
      usedTimeToReadSolution: (usedTimeToReadSolution / 1000).toFixed(2)
    });
  },
  postRoundEvaluationLogEntry: function postRoundEvaluationLogEntry(roundId, usedTimeToReadRoundEvaluation, viewedQuestions) {
    var type = 'RoundEvaluationLog';
    return (0, _CordovaHelper.postLogEntry)(LOG_CONTEXT, type, {
      type: type,
      userid: this.USER_ID,
      round: roundId,
      questions: viewedQuestions,
      usedTimeToReadEvaluation: (usedTimeToReadRoundEvaluation / 1000).toFixed(2)
    });
  }
};
exports["default"] = _default;