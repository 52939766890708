"use strict";

var _Object$defineProperty = require("@babel/runtime-corejs2/core-js/object/define-property");

_Object$defineProperty(exports, "__esModule", {
  value: true
});

exports["default"] = void 0;
var STRINGS = {
  UNLOCK_MASTER_FUNCTIONALITIES: {
    de: 'Trainerfunktion aktivieren'
  },
  UNLOCK_MASTER_FUNCTIONALITIES_DIALOG_TEXT: {
    de: 'Um die Trainerfunktionen freizuschalten, geben Sie bitte den <b>Aktivierungscode</b> ein.'
  },
  UNLOCK_MASTER_FUNCTIONALITIES_DIALOG_TITLE: {
    de: 'Aktivieren'
  },
  REMOVE_MASTER_FUNCTIONALITIES: {
    de: 'Trainerfunktionen deaktivieren'
  },
  TO_VOTING: {
    de: 'Zur Abstimmung'
  }
};
var _default = STRINGS;
exports["default"] = _default;